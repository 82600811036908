import React from 'react';
import SbEditable from 'storyblok-react';
import truncate from 'lodash/truncate';

import { getFluidGatsbyImage } from '../utils/gatsby-storyblok-image-fixed';

import Intro3 from './intro3';
import AboutProject from './about-project';
import DetailsSteps from './details-steps';
import SectionCarousel from './section-carousel';
import RichtextParser from '../utils/richtext-parser';
import StoryblokComponent from './storyblok/storyblok-component';
import { Box } from './UIKit/system';

function CaseStudy({
  about,
  additionalContent,
  caseStudyStages,
  featuredImage,
  gallery,
  subtitle,
  title,
}) {
  const fluidFeaturedImage = getFluidGatsbyImage(featuredImage, {
    maxWidth: 1920,
  });
  const fluidCompanyImage = getFluidGatsbyImage(about[0].companyLogo, {
    maxWidth: 550,
  });

  const galleryImagesParsed =
    gallery &&
    gallery.map((image) => {
      return getFluidGatsbyImage(image, {
        maxWidth: 1080,
      });
    });

  let stagesConv = [];
  caseStudyStages[0].stages.forEach((stage) => {
    let stageItem = {
      text: RichtextParser(stage.paragraph),
      ...stage,
    };
    stagesConv.push(stageItem);
  });
  return (
    <div>
      <Intro3
        pagination={[
          {
            link: '/case-studies/',
            name: 'Our Work',
          },
          {
            name: truncate(title, { length: 80 }),
          },
        ]}
        title={title}
        text={subtitle}
        image={fluidFeaturedImage}
      />
      <AboutProject
        text={about[0].infoParagraph}
        specification={about[0].projectStats}
        image={fluidCompanyImage}
        imageTitle={about[0].companyTitle}
        imageDescription={about[0].companyParagraph}
      />
      {galleryImagesParsed && galleryImagesParsed.length > 0 && (
        <Box mb={[-70, null, -80]}>
          <SectionCarousel images={galleryImagesParsed} />
        </Box>
      )}
      <DetailsSteps
        smallPaddingTop
        title={caseStudyStages[0].title}
        sections={stagesConv}
      />
      {additionalContent &&
        additionalContent.map((blok) => {
          return (
            <SbEditable key={blok._uid} content={blok}>
              <StoryblokComponent {...blok} />
            </SbEditable>
          );
        })}
    </div>
  );
}

export default CaseStudy;
