import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import ReactSwiper from './react-swiper';
import LazyImage from './lazy-image';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import {
  getThemeColor,
  hoverDefault,
  hoverScaleDefault,
} from '../utils/styles';
import ArrowRight from '../images/svg/arrow-right.svg';
import { Box } from './UIKit/system';
import { vw } from '../utils/size-functions';
import mq from '../utils/mq';

const StyledCarousel = styled(Box)`
  position: relative;
  user-select: none;
`;

const Image = styled(LazyImage)`
  display: block;
  width: ${vw(710)};
  height: ${vw(520)};

  ${mq.desktopExtraLarge(css`
    width: 710px;
    height: 520px;
  `)};
`;

const StyledNavigationButton = styled.div`
  ${hoverScaleDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;
  margin-left: 47px;
  margin-right: 47px;

  &:hover {
    transform: translateY(-50%) scale(1.025);
  }

  &:active {
    transform: translateY(-50%) scale(1.05);
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.prev &&
    css`
      left: 0;

      svg {
        transform: scaleX(-1);
      }
    `};

  ${(props) =>
    props.next &&
    css`
      right: 0;
    `}
`;

const StyledArrowRight = styled(ArrowRight)`
  stroke: ${getThemeColor('black')};
`;

const NavigationButton = React.forwardRef((props, ref) => {
  return (
    <StyledNavigationButton {...props} ref={ref}>
      <StyledArrowRight />
    </StyledNavigationButton>
  );
});

const StyledSlide = styled(ReactSwiper.Item)`
  opacity: 0.7;
  transition: opacity 0.55s ease-out;

  &.swiper-slide-active {
    opacity: 1;
  }
`;

function Carousel({ className, swiperOptions, items, ...rest }) {
  const [swiperOptionsMerged, setSwiperOptionsMerged] = useState(null);
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);
  const [slidePos, setSlidePos] = useState(1);

  useEffect(() => {
    const optionsDefault = {
      loop: true,
      spaceBetween: 16,
      centeredSlides: true,
      slidesPerView: 'auto',
      watchOverflow: true,
      navigation: {
        prevEl: prevElRef.current,
        nextEl: nextElRef.current,
      },
    };

    if (!swiperOptions) {
      setSwiperOptionsMerged(optionsDefault);
      return;
    }

    setSwiperOptionsMerged({
      ...optionsDefault,
      ...swiperOptions,
    });
  }, []);

  function handleNext() {
    if (slidePos !== items.length) {
      setSlidePos(slidePos + 1);
    } else {
      setSlidePos(1);
    }
  }

  function handlePrev() {
    setSlidePos(slidePos - 1);
  }

  const lightboxOptions = {
    buttons: {
      disableKeyboardControls: true,
      showAutoplayButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      showDownloadButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <StyledCarousel className={className}>
      <SimpleReactLightbox>
        <SRLWrapper images={items} options={lightboxOptions}>
          <ReactSwiper options={swiperOptionsMerged} {...rest}>
            {items.map((item, index) => {
              return (
                <StyledSlide key={index}>
                  <Image url={item.src} />
                </StyledSlide>
              );
            })}
          </ReactSwiper>
        </SRLWrapper>
      </SimpleReactLightbox>
      <NavigationButton
        prev
        ref={prevElRef}
        onClick={handlePrev}
        style={{ display: slidePos === 1 ? 'none' : 'flex' }}
      />
      <NavigationButton next ref={nextElRef} onClick={handleNext} />
    </StyledCarousel>
  );
}

export default Carousel;
