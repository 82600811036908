import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Box, Flex } from '../UIKit/system';
import Container from '../container';
import GridCol from '../grid-col';
import Title from '../title';
import ProductCarousel from './product-carousel';
import Button from '../button';
import ButtonPlay from '../button-play';
import { darkBackground } from '../../utils/styles';
import LazyImage from '../lazy-image';
import Breadcrumbs from '../breadcrumbs';
import mq from '../../utils/mq';
import VideoPlayerOverlay from '../video-player-overlay';
import { openChat } from '../../utils/utils';
import WipeAnimation from '../wipe-animation';

const StyledProductIntro = styled(Box)`
  ${(props) =>
    props.altStyling &&
    css`
      padding-bottom: 0;
    `};

  ${(props) =>
    props.dark &&
    css`
      ${darkBackground};
      color: #fff;
    `};
`;

const OuterContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
`;

const StyledContainer = styled(Container)`
  margin-left: -10px !important;
  margin-right: 0px !important;
  display: flex;
  justify-content: ${(props) => props.align};
  ${mq.tablet(css`
    flex-wrap: wrap;
  `)};
`;

const StyledGridCol = styled(GridCol)`
  ${mq.desktopLarge(css`
    max-width: 555px;
  `)}
  ${mq.tablet(css`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 34px;
  `)};
  ${mq.mobile(css`
    text-align: left;
    align-items: flex-start;
  `)};
`;

const ImageArea = styled.div`
  ${darkBackground};

  margin-top: 153px;
  ${mq.mobile(css`
    margin-top: 78px;
  `)};
`;

const ImageAreaInner = styled(motion.div)`
  top: -90px;
  margin-bottom: -90px;
  position: relative;
`;

const Image = styled(LazyImage)`
  position: relative;
`;

const ImageAnimLayer = styled(motion.div)``;

const StyledButton = styled(Button)`
  background-color: #753fbf;
  color: #fff;
  :hover {
    background-color: #000;
  }
`;

const ButtonPlayContainer = styled.div`
  ${mq.tablet(css`
    margin-bottom: 20px;
  `)};
`;

function ProductIntro({
  title,
  carouselImages,
  bottomImage,
  pagination,
  videoLink,
}) {
  const altStyling = !!bottomImage;
  const [videoOpen, setVideoOpen] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  const ZoomOutAnim = {
    hidden: {
      scale: 1.1,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.6,
        ease: 'easeIn',
      },
    },
  };

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  function handlePopupClose() {
    setVideoOpen(false);
  }

  return (
    <StyledProductIntro pt={28} pb={55} altStyling={altStyling}>
      {videoLink && videoOpen && (
        <VideoPlayerOverlay
          videoLink={videoLink}
          handlePopupClose={handlePopupClose}
        />
      )}
      <OuterContainer>
        <StyledContainer
          as={Flex}
          px={[0, 10, 10]}
          align={carouselImages ? 'center' : 'flex-start'}
          alignItems={'center'}
        >
          <StyledGridCol>
            {pagination && <Breadcrumbs mb={20} items={pagination} />}
            <Box
              minHeight={altStyling ? 0 : [null, 'unset', 0, 160]}
              mb={[29, 29, 48, 48]}
            >
              <Title
                as={'h1'}
                lineHeight={'50px'}
                fontSizeMedium
                html={title}
              />
            </Box>
            <Flex
              alignItems={'center'}
              justifyContent={['flex-start', 'center', 'flex-start']}
              width={['100%', null, null, null]}
              flexWrap={['wrap', null, null, null, null]}
            >
              <StyledButton
                ml={[0, 18, 0]}
                mr={[36, 18, 36]}
                mb={[20, 20, 0]}
                onClick={openChat}
              >
                Talk to us
              </StyledButton>
              {videoLink && (
                <ButtonPlayContainer onClick={() => setVideoOpen(true)}>
                  <ButtonPlay>Watch video</ButtonPlay>
                </ButtonPlayContainer>
              )}
            </Flex>
          </StyledGridCol>
          {carouselImages && <ProductCarousel items={carouselImages} />}
        </StyledContainer>
      </OuterContainer>
      {bottomImage && (
        <ImageArea>
          <ImageAreaInner>
            <WipeAnimation
              width={'100%'}
              height={'100%'}
              overflow
              threshold={0}
            >
              <Container style={{ overflow: 'hidden' }}>
                <ImageAnimLayer
                  ref={ref}
                  initial={'hidden'}
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={ZoomOutAnim}
                >
                  <Image fluid={bottomImage} />
                </ImageAnimLayer>
              </Container>
            </WipeAnimation>
          </ImageAreaInner>
        </ImageArea>
      )}
    </StyledProductIntro>
  );
}

export default ProductIntro;
