import React from 'react'
import TextContent from './text-content'
import Links from './links';
import Videos from './videos';
import Shapes from './shapes';
import ComparisonTable from './feature-table'

const NoComponent = ({ type }) => <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>No content type for <pre>{type}</pre></div>

const CONTENT_TYPES = {
  text_content: {
    component: TextContent,
  },
  links: {
    component: Links,
  },
  videos: {
    component: Videos,
  },
  shapes: {
    component: Shapes,
  },
  feature_table: {
    component: ComparisonTable
  }
};

export function getContentComponent (contentType) {
  const type = CONTENT_TYPES[contentType]
  if (!type) return NoComponent
  return type.component;
}
