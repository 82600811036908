import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import StyledLink from './styled-link';
import LazyImage from './lazy-image';
import Title from './title';
import { supportsGrid } from '../utils/styles';
import ellipsis from '../utils/ellipsed';
import mq from '../utils/mq';

const StyledProductPreview = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  text-align: center;
  min-height: 420px;
  width: 297px;

  ${supportsGrid(css`
    display: grid;
    grid-template-rows: 1.75fr 0.3fr 0.5fr;
  `)}

  ${(props) =>
    props.altStyling &&
    css`
      max-width: 242px;
      min-height: 350px;

      ${supportsGrid(css`
        grid-template-rows: 1.45fr 0.3fr 0.5fr;
      `)}
    `};

  ${mq.mobile(css`
    min-height: 300px;
    width: 100%;
  `)};
`;

const ImageContainer = styled(StyledLink)`
  display: flex;
  justify-content: center;
  width: 100%;

  ${supportsGrid(css`
    height: 100%;
  `)}
`;

const ImageContainerInner = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Image = styled(LazyImage)`
  display: block;
  max-width: 75%;
  height: 100%;
  width: 100%;
  max-height: 95%;
`;

const StyledTitle = styled(Title)`
  line-height: 1.4;
  margin-bottom: 25px;

  ${supportsGrid(css`
    margin-bottom: 0;
    align-self: flex-start;
  `)}
`;

const ViewButton = styled(StyledLink)`
  ${mq.mobile(css`
    font-size: 13px;
  `)};
`;

function ProductPreview({ name, image, link, altStyling }) {
  const titleRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!titleRef.current) {
      return;
    }

    ellipsis(titleRef.current, 2);
  }, []);

  return (
    <StyledProductPreview
      altStyling={altStyling}
      onHoverStart={() => {
        setIsHovered(true);
      }}
      onHoverEnd={() => {
        setIsHovered(false);
      }}
    >
      <ImageContainer to={link} altStyling={altStyling}>
        <ImageContainerInner
          animate={{ scale: isHovered ? 1.075 : 1.0 }}
          transition={{ duration: 1 }}
        >
          <Image fluid={image} />
        </ImageContainerInner>
      </ImageContainer>
      <StyledTitle
        px={25}
        fontSize={altStyling ? [13, 16, 16] : [13, 16, 16]}
        textAlign={'center'}
      >
        <span ref={titleRef}>{name}</span>
      </StyledTitle>
      <ViewButton to={link}>View</ViewButton>
    </StyledProductPreview>
  );
}

export default ProductPreview;
