import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Text } from '../UIKit/system';
import Title from '../title';
import LazyImage from '../lazy-image';
import { getThemeColor } from '../../utils/styles';
import mq from '../../utils/mq';
import VideoPlayerOverlay from '../video-player-overlay';
import PlayIcon from '../../images/svg/play.svg';

const StyledItem = styled.div`
  display: flex;
  width: 733px;
  height: 180px;
  min-height: 180px;
  max-width: 100%;
  background-color: ${getThemeColor('lightGrey')};
  align-items: center;
  margin-bottom: 16px;
  ${mq.mobile(css`
    flex-wrap: wrap;
    width: 95%;
    height: unset;
  `)};
`;

const Image = styled(LazyImage)`
  display: block;
  height: 100%;
`;

const PlayIconContainer = styled.div`
  height: 100%;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContent = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PlayButton = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
  border-radius: 50%;
`;

const StyledBox = styled(Box)`
  min-width: 300px;
  ${mq.mobile(css`
    min-width: unset;
  `)};
`;

function Item({ video, title, category, duration }) {
  const [videoOpen, setVideoOpen] = useState(false);
  function handlePopupClose() {
    setVideoOpen(false);
  }
  return (
    <StyledItem onClick={() => setVideoOpen(true)}>
      {video && (
        <React.Fragment>
          {videoOpen ? (
            <VideoPlayerOverlay
              videoLink={video.url}
              handlePopupClose={handlePopupClose}
            />
          ) : null}
        </React.Fragment>
      )}
      <StyledBox width={['100%', '300px', '300px']} height={'100%'}>
        {video && <Image fluid={video.backgroundImage} />}
      </StyledBox>
      <Flex
        justifyContent={'space-between'}
        alignItems={['center', 'center', 'space-between']}
        height={'100%'}
        width={['100%', '100%', 'unset']}
        px={25}
        py={28}
      >
        <TextContent>
          <Text grey fontSizeExtraSmall>
            {category}
          </Text>
          <Title fontSize={[20, 18, 20]} width={['100%', '280px', '280px']}>
            {title}
          </Title>
          <Text grey fontSizeExtraSmall>
            {duration}
          </Text>
        </TextContent>
        <PlayIconContainer>
          <PlayButton>
            <PlayIcon fill={'#000'} width={11} height={11} />
          </PlayButton>
        </PlayIconContainer>
      </Flex>
    </StyledItem>
  );
}

const StyledVideo = styled.div``;

function Videos({ items }) {
  return (
    <StyledVideo>
      {items.map((item, index) => {
        return <Item key={index} {...item} />;
      })}
    </StyledVideo>
  );
}

export default Videos;
