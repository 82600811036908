import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react"
import Toggle from 'react-toggle'

import StoryblokClient from 'storyblok-js-client'

import {public as  storyblokPublicKey } from '../../storyblok-api-key'

// Components
import BannerWithCta from '../banner-with-cta'
import Container from "../container"
import SectionTabs from '../section-tabs'

// Styles
import { StyledProductSelectorContainer, StyledProductSelector, StyledProductSelectorItem, StyledProductSelectorItemPrice, StyledProductSelectorContent, StyledProductSelectorTabs, StyledToggle } from "./styles"

// Types
import { ProductSelectorItem } from './types'

interface Props {
  products: ProductSelectorItem[];
  cta: {
    title: any;
    text: any;
    button: any[];
    backgroundImage: any;
  }[]
}

const Storyblok = new StoryblokClient({ accessToken: storyblokPublicKey })

const ProductSelector: FunctionComponent<Props> = ({ products, cta }) => {
  const [ctaItem] = useMemo(() => cta.map(item => {
    const [button] = item.button
    if (!button) return item

    const url = button.link.url ? new URL(button.link.url).pathname : ''

    return {
      ...item,
      button: {
        ...button,
        to: url
      }
    }
  }), [])

  // eslint-disable-next-line no-undef
  const types = ['horizontal', 'vertical']
  const [initialType, secondaryType] = types.map(type => {
    const items = products.filter(product => {
      const productType = product.type || 'horizontal'
      return productType === type
    }).map((item, id) => ({ ...item, id }))

    return { type, items }
  })

  const [currentType, setCurrentType] = useState(initialType)
  const handleSetCurrentType = useCallback(() => {
    if (currentType.type === initialType.type) return setCurrentType(secondaryType)
    return setCurrentType(initialType)
  }, [currentType])


  const [currentItem, setCurrentItem] = useState<ProductSelectorItem>(currentType.items?.[0])
  const handleSetCurrentItem = useCallback((item: ProductSelectorItem) => setCurrentItem(item), [])
  useEffect(() => {
    if(currentType.items.length) setCurrentItem(currentType.items[0])
  }, [currentType])


  const formattedProducts = useMemo(() => currentType.items.filter(product => product?.product?.content).flatMap((item) => ({
    ...item.product.content,
    name: item.product.content.productName,
    image: item.product.content.productThumbnail,
    id: item.id
  })), [currentType])

  const currentItemTabs = useMemo(() => {
    const specifications = currentItem.techSpecs?.flatMap<any>(spec => {
      if (spec.component === 'shapesAndSizesContent') {
        return [{
          type: 'shapes',
          items: [{ ...spec, title: 'Tech Specs' }]
        }]
      }

      if (spec.component === 'techSpecItem') {
        return [{
          type: 'text_content',
          items: [{
            title: spec.title,
            text: spec.content
          }]
        }]
      }

      return []
    }) ?? []

    const downloads = currentItem.downloads?.map(download => ({
      title: download.title,
      text: download.description,
      button: { children: download.ctaButtonText, to: download.file?.filename }
    })) ?? []

    const sectionTabs = []
    if (specifications.length) sectionTabs.push({ name: 'Tech Specs', content: specifications })
    if (formattedProducts.length) sectionTabs.push({ name: 'Comparison Table', content: [{ type: 'feature_table', products: formattedProducts }] })
    if (downloads.length) sectionTabs.push({ name: 'Downloads', content: [{ type: 'links', items: downloads }] })

    return sectionTabs
  }, [currentItem])

  const formatProductPrice = (value: string | number) => value.toString().includes('£') || isNaN(Number(value.toString())) ? value : new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(Number(value))

  return (
    <StyledProductSelectorContainer>
      <Container large>
        <StyledToggle>
          <span className="label">{initialType.type}</span>
          <Toggle
            checked={currentType.type !== initialType.type}
            icons={false}
            onChange={handleSetCurrentType} />
          <span className="label">{secondaryType.type}</span>
        </StyledToggle>
        <StyledProductSelector>
          {currentType.items.map((item, index) => {
            const title = item.title || item.product.name || item.product.content.productName
            const thumbnail = item.image?.filename ? item.image : item.product?.content?.productThumbnail
            const price = item.price || item.product?.content?.guidePrice
            const popoverText = item.pricePopover

            return (
              <StyledProductSelectorItem
                className={currentItem && currentItem._uid === item._uid ? 'active' : ''}
                key={index}
                onClick={() => handleSetCurrentItem(item)}
              >
                <div className="preview">
                  <div className="thumbnail">{thumbnail && <img src={thumbnail.filename} alt={thumbnail.alt || title}/>}</div>
                  <p className="title">{title}</p>
                </div>
                {price && (
                  <StyledProductSelectorItemPrice>
                    <p className="price">{formatProductPrice(price)}</p>
                    {popoverText && (
                      <div className="popup">
                        <svg className="popup-icon" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.3043 13.6486C9.91787 14.0215 9.43478 14.208 8.85507 14.208C8.27536 14.208 7.81804 14.0889 7.48309 13.8506C7.16103 13.6124 7 13.3016 7 12.9183C7 12.7836 7.10306 12.219 7.30918 11.2245L7.94686 8.44308H7.13527L7.17391 8.16338H8.00483C8.95813 8.16338 9.66023 8.13231 10.1111 8.07015L10.3816 8.02353L9.04831 13.8972C9.43478 13.8662 9.75684 13.7211 10.0145 13.4621L10.3043 13.6486ZM8.60386 6.5318C8.33333 6.31426 8.19807 6.0501 8.19807 5.73932C8.19807 5.42854 8.33333 5.16438 8.60386 4.94684C8.8744 4.71893 9.2029 4.60498 9.58937 4.60498C9.97585 4.60498 10.3043 4.71893 10.5749 4.94684C10.8583 5.16438 11 5.42854 11 5.73932C11 6.0501 10.8583 6.31426 10.5749 6.5318C10.3043 6.74935 9.97585 6.85812 9.58937 6.85812C9.2029 6.85812 8.8744 6.74935 8.60386 6.5318Z" fill="#753FBF"/>
                          <path d="M17.2 9.40649C17.2 13.7077 13.5596 17.2492 9 17.2492C4.44041 17.2492 0.8 13.7077 0.8 9.40649C0.8 5.10532 4.44041 1.56379 9 1.56379C13.5596 1.56379 17.2 5.10532 17.2 9.40649Z" stroke="#753FBF" strokeWidth="1.6"/>
                        </svg>
                        <p className="popup-text">{popoverText}</p>
                      </div>
                    )}
                  </StyledProductSelectorItemPrice>
                )}
              </StyledProductSelectorItem>
            )
          })}
        </StyledProductSelector>
      </Container>
      {ctaItem && <BannerWithCta {...ctaItem} whiteTransparent={false} color="white" hasContainer={false} />}
      {currentItem.content && (
        <div>
          {currentItem.content.map((item, index) => (
            <StyledProductSelectorContent key={index}>
              <div className="content">
                <p className="title" dangerouslySetInnerHTML={{ __html: item.title }}></p>
                <div className="text" dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(item.content) }}></div>
              </div>
              <div className="image">
                {item.image?.filename && <img src={item.image.filename} alt={item.image.alt} />}
              </div>
            </StyledProductSelectorContent>
          ))}
          {currentItemTabs && (
            <StyledProductSelectorTabs>
              <p className="title">{currentItem.tabsTitle}</p>
              {currentItem.tabsText && <div className="text" dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(currentItem.tabsText) }}></div>}
              <SectionTabs items={currentItemTabs} current={currentItem?.id} extended productName="" />
            </StyledProductSelectorTabs>
          )}
        </div>
      )}
    </StyledProductSelectorContainer>
  )
}

export default ProductSelector
